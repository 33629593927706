@import "variables.scss";
@import "mixins.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: $tb-font-family;
}
*:focus {
  outline: none;
}
a {
  text-decoration: none;
  color: $tb-brand;
}
input {
  -webkit-appearance: none;
  background-color: $tb-white;
}
body {
  background: $tb-offwhite;
  color: $tb-font-body;
  font-weight: 300;
}
table {
  border-collapse: collapse;
}
[class*="col_"] {
  float: left;
}
.tb_l_f_font_defaults,
.tb_l_font_defaults {
  font-size: $tb-l-units-body;
  font-weight: 400;
  line-height: $tb-l-lineheight;
  font-family: $tb-font-family;
  h1 {
    font-size: $tb-l-units-larger;
    font-weight: 700;
    line-height: $tb-l-lineheight_h1;
  }
  h2 {
    font-size: $tb-l-units-large;
    font-weight: 700;
    line-height: $tb-l-lineheight_h2;
  }
  .tb-l-force-blocker {
    @include clearfix;
    margin-bottom: $tb-l-units-large;
  }
}
.tb_l_shadow__true {
  box-shadow: $tb-l-shadow;
}
.tb_l_shadow__bottom {
  box-shadow: $tb-l-shadow-bottom;
}
.shadowtrue {
  box-shadow: $tb-l-shadow;
}
.tb_l_spacer__large {
  padding: 24px;
}
.tb-l-clear {
  @include clearfix();
}
.tb_l_divider {
  margin: 0;
  border: 1px solid rgb(224, 224, 224);
  border-width: 1px 0 0;
}

.tb_l_divider__light {
  border-color: #ededed;
}
.tb_l_grid {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: -8px -4px 0 -4px;
  clear: both; /* Fix for floated elements before the col, that break due to negative margin */
  @include clearfix();
}
.tb_l_spacer_small__left {
  margin-left: $tb-l-units-smaller;
}
.tb_l_spacer_small__right {
  margin-right: $tb-l-units-smaller;
}
.tb_l_spacer_large__left {
  @include clearfix;
  margin-left: $tb-l-units-larger;
}
.tb_l_spacer_large__right {
  @include clearfix;
  margin-right: $tb-l-units-larger;
}
.tb_l_list {
  .tb_l_list__item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .tb_l_list__icon {
      display: inline-block;
      width: 12px;
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }
}
.tb_l_spacer__large {
  @include clearfix;
  padding: $tb-l-units-larger;
}
.tb_l_spacer__largest {
  @include clearfix;
  padding: $tb-l-units-largest;
}
.tb_l_spacer__medium {
  @include clearfix;
  padding: $tb-l-units-medium;
}
.tb_l_spacer_large__left {
  @include clearfix;
  margin-left: $tb-l-units-larger;
}
.tb_l_spacer_large__right {
  @include clearfix;
  margin-right: $tb-l-units-larger;
}
.tb_l_spacer {
  margin-bottom: 24px !important;
}

.tb_l_spacer__smaller {
  margin-bottom: 2px !important;
}

.tb_l_spacer__small {
  margin-bottom: 4px !important;
}

.tb_l_spacer_b__medium {
  margin-bottom: 8px !important;
}

.tb_l_spacer__large {
  &:last-child {
    margin-bottom: 0 !important;
  }
  margin-bottom: 16px !important;
}
.tb_l_spacer__larger {
  margin-bottom: 24px !important;
}
.tb_l_spacer__largest {
  margin-bottom: 32px !important;
  &:last-child {
    margin-bottom: 0 !important;
  }
}
.tb_l_force_blocker {
  margin-bottom: $tb-l-units-large;
}
.tb_l_pull_left {
  float: left;
}
.tb_l_pull_right {
  float: right;
}
.tb-l-time-form {
  h1 {
    padding: $tb-l-units-medium;
    border-bottom: 1px solid #e0e0e0;
  }
  fieldset {
    border: none;
  }
}
.tb_l_form__group {
  border: none;
  padding: 0;
  margin: 0;
  label {
    margin-bottom: 4px;
    font-size: $tb-l-units-body;
    font-weight: 400;
    line-height: 1.4285714286em;
  }
}
.tb_l_form__label {
  margin-bottom: 4px;
  font-size: $tb-l-units-body;
  font-weight: 400;
  line-height: 1.4285714286em;
}
.tb_l_form__control {
  font-size: $tb-l-units-body;
  font-weight: 400;
  line-height: 1.4285714286em;
  background: $tb-white;
  border-radius: $tb-l-units-micro;
  border: 1px solid #d1d1d1;
  display: block;
  padding: 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.tb_l_radio {
  position: relative;
  display: block;
}
.tb_l_checkbox {
  position: relative;
  display: block;
}

.tb_l_checkbox__input {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.tb_l_checkbox__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286em;
  display: inline-block;
  position: relative;
  min-height: 14px;
  padding: 0 0 0 28px;
}

.rtl .tb_l_checkbox__label {
  padding: 0 28px 0 0;
}

.tb_l_checkbox__label:before,
.tb_l_checkbox__label:after {
  content: "";
  background-color: #fff;
  border: 2px rgba(0, 0, 0, 0.5) solid;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
}

.tb_l_checkbox__label:after {
  border: 0;
  background: transparent;
}

.rtl .tb_l_checkbox__label:before,
.rtl .tb_l_checkbox__label:after {
  left: auto;
  right: 0;
}

/* Error state */

.tb_l_has_error .tb_l_checkbox__label:before,
.tb_l_has-error .tb_l_checkbox__label:after {
  border-color: #e21111;
}

/* Indeterminate state */

.tb_l_checkbox__input:indeterminate ~ .tb_l_checkbox__label:before,
.tb_l_checkbox__input--indeterminate ~ .tb_l_checkbox__label:before {
  border: none;
  background: none;
  background-color: #0077cc;
}

.tb_l_checkbox__input:indeterminate ~ .tb_l_checkbox__label:after,
.tb_l_checkbox__input--indeterminate ~ .tb_l_checkbox__label:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: #fff;
}

.rtl .tb_l_checkbox__input:indeterminate ~ .tb_l_checkbox__label:after,
.rtl .tb_l_checkbox__input--indeterminate ~ .tb_l_checkbox__label:after {
  left: auto;
  right: 5px;
}

/* Checked state */

.tb_l_checkbox__input:checked ~ .tb_l_checkbox__label:before {
  border-color: #0077cc;
}

.tb_l_checkbox__input:checked ~ .tb_l_checkbox__label:after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
  border: 0;
  left: 0;
  top: 0;
  border-color: #0077cc;
  background-color: #0077cc;
  height: 20px;
  width: 20px;
}

/* Disabled state */

.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label:before,
.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label:after {
  border-color: #bdbdbd;
  cursor: not-allowed;
}

.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label:after {
  background-color: #bdbdbd;
}

.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label {
  color: #bdbdbd;
}

/* Focus state */

.tb_l_checkbox__input:focus ~ .tb_l_checkbox__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3);
  box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3);
}

.tb_l_has_error
  .tb_l_checkbox__input:not(:checked):focus
  ~ .tb_l_checkbox__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
  box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
}
/* Validation */

.tb_l_has_error .tb_l_form__control {
  border-color: #e21111;
}

.tb_l_has_error .tb_l_form__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
  box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
}

.tb_l_has_error .tb_l_form__error {
  display: block;
}
.tb_l_radio__input {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.tb_l_radio__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286em;
  display: inline-block;
  position: relative;
  padding: 0 0 0 28px;
}

.rtl .tb_l_radio__label {
  padding: 0 28px 0 0;
}

.tb_l_radio__label:before,
.tb_l-radio__label:after {
  content: "";
  background-color: $tb-white;
  border: 2px rgba(0, 0, 0, 0.5) solid;
  border-radius: 50%;
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
}

.rtl .tb_l_radio__label:before,
.rtl .tb_l_radio__label:after {
  left: auto;
  right: 0;
}

.tb_l_radio__label:after {
  border-color: $tb-white;
  height: 10px;
  width: 10px;
  top: 5px;
  left: 5px;
  opacity: 0;
}

.rtl .tb_l_radio__label:after {
  left: auto;
  right: 5px;
}

.tb_l_radio__input:checked ~ .tb_l_radio__label:before {
  border-color: $tb-brand;
}

.tb_l_radio__input:checked ~ .tb_l_radio__label:after {
  border-color: $tb-brand;
  background-color: $tb-brand;
  opacity: 1;
}

.tb_l_has_error .tb_l_radio__label:before {
  border-color: #e21111;
}

.tb_l_radio__input:disabled ~ .tb_l_radio__label:before,
.tb_l_radio__input:disabled ~ .tb_l_radio__label:after {
  border-color: #bdbdbd;
  cursor: not-allowed;
}

.tb_l_radio__input:disabled ~ .tb_l_radio__label:after {
  background-color: #bdbdbd;
}

.tb_l_radio__input:disabled ~ .tb_l_radio__label {
  color: #bdbdbd;
}

/* Focus state */

.tb_l_radio__input:focus ~ .tb_l_radio__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3);
  box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3);
}

.tb_l_has_error
  .tb_l_radio__input:not(:checked):focus
  ~ .tb_l_radio__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
  box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
}
