.region-reg-container {
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: #eee;
    display: flex;
    justify-content: center;
  }

  .region-reg-container input {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    margin: 15px;
    display: block;
}