.logo {
  display: inline-block;
}

.logo img {
  display: inline-block;
  width: 70px;
  height: auto;
}

.logo span {
  display: inline-block;
  margin-left: 16px;
  border-left: 1px solid #D0D0D0;
  padding-top: 4px;
  padding-left: 16px;
  padding-bottom: 4px;
  position: relative;
  top: -7px;
  color: #6D6EED;
}