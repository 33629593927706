.item {
    margin-bottom: 16px;
}

.item:last-child {
    margin-bottom: 0;
  }

.icon {
    display: inline-block;
    width: 12px;
    margin-right: 8px;
    position: relative;
    top: 2px;
  }
.icon .img {
    width: 100%;
    height: auto;
  }

.body {
    display: inline-block;
}