html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif; }

*:focus {
  outline: none; }

a {
  text-decoration: none;
  color: #6d6eed; }

input {
  -webkit-appearance: none;
  background-color: #fff; }

body {
  background: #f7f8fc;
  color: #383838;
  font-weight: 300; }

table {
  border-collapse: collapse; }

[class*="col_"] {
  float: left; }

.tb_l_f_font_defaults,
.tb_l_font_defaults {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857em;
  font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif; }
  .tb_l_f_font_defaults h1,
  .tb_l_font_defaults h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33333em; }
  .tb_l_f_font_defaults h2,
  .tb_l_font_defaults h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4em; }
  .tb_l_f_font_defaults .tb-l-force-blocker,
  .tb_l_font_defaults .tb-l-force-blocker {
    margin-bottom: 20px; }
    .tb_l_f_font_defaults .tb-l-force-blocker:before, .tb_l_f_font_defaults .tb-l-force-blocker:after,
    .tb_l_font_defaults .tb-l-force-blocker:before,
    .tb_l_font_defaults .tb-l-force-blocker:after {
      content: " ";
      display: table; }
    .tb_l_f_font_defaults .tb-l-force-blocker:after,
    .tb_l_font_defaults .tb-l-force-blocker:after {
      clear: both; }
    .tb_l_f_font_defaults .tb-l-force-blocker,
    .tb_l_font_defaults .tb-l-force-blocker {
      *zoom: 1; }

.tb_l_shadow__true {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.tb_l_shadow__bottom {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.1); }

.shadowtrue {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.tb_l_spacer__large {
  padding: 24px; }

.tb-l-clear:before, .tb-l-clear:after {
  content: " ";
  display: table; }

.tb-l-clear:after {
  clear: both; }

.tb-l-clear {
  *zoom: 1; }

.tb_l_divider {
  margin: 0;
  border: 1px solid #e0e0e0;
  border-width: 1px 0 0; }

.tb_l_divider__light {
  border-color: #ededed; }

.tb_l_grid {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: -8px -4px 0 -4px;
  clear: both;
  /* Fix for floated elements before the col, that break due to negative margin */ }
  .tb_l_grid:before, .tb_l_grid:after {
    content: " ";
    display: table; }
  .tb_l_grid:after {
    clear: both; }
  .tb_l_grid {
    *zoom: 1; }

.tb_l_spacer_small__left {
  margin-left: 8px; }

.tb_l_spacer_small__right {
  margin-right: 8px; }

.tb_l_spacer_large__left {
  margin-left: 24px; }
  .tb_l_spacer_large__left:before, .tb_l_spacer_large__left:after {
    content: " ";
    display: table; }
  .tb_l_spacer_large__left:after {
    clear: both; }
  .tb_l_spacer_large__left {
    *zoom: 1; }

.tb_l_spacer_large__right {
  margin-right: 24px; }
  .tb_l_spacer_large__right:before, .tb_l_spacer_large__right:after {
    content: " ";
    display: table; }
  .tb_l_spacer_large__right:after {
    clear: both; }
  .tb_l_spacer_large__right {
    *zoom: 1; }

.tb_l_list .tb_l_list__item {
  margin-bottom: 16px; }
  .tb_l_list .tb_l_list__item:last-child {
    margin-bottom: 0; }
  .tb_l_list .tb_l_list__item .tb_l_list__icon {
    display: inline-block;
    width: 12px;
    margin-right: 8px;
    position: relative;
    top: 2px; }

.tb_l_spacer__large {
  padding: 24px; }
  .tb_l_spacer__large:before, .tb_l_spacer__large:after {
    content: " ";
    display: table; }
  .tb_l_spacer__large:after {
    clear: both; }
  .tb_l_spacer__large {
    *zoom: 1; }

.tb_l_spacer__largest {
  padding: 32px; }
  .tb_l_spacer__largest:before, .tb_l_spacer__largest:after {
    content: " ";
    display: table; }
  .tb_l_spacer__largest:after {
    clear: both; }
  .tb_l_spacer__largest {
    *zoom: 1; }

.tb_l_spacer__medium {
  padding: 16px; }
  .tb_l_spacer__medium:before, .tb_l_spacer__medium:after {
    content: " ";
    display: table; }
  .tb_l_spacer__medium:after {
    clear: both; }
  .tb_l_spacer__medium {
    *zoom: 1; }

.tb_l_spacer_large__left {
  margin-left: 24px; }
  .tb_l_spacer_large__left:before, .tb_l_spacer_large__left:after {
    content: " ";
    display: table; }
  .tb_l_spacer_large__left:after {
    clear: both; }
  .tb_l_spacer_large__left {
    *zoom: 1; }

.tb_l_spacer_large__right {
  margin-right: 24px; }
  .tb_l_spacer_large__right:before, .tb_l_spacer_large__right:after {
    content: " ";
    display: table; }
  .tb_l_spacer_large__right:after {
    clear: both; }
  .tb_l_spacer_large__right {
    *zoom: 1; }

.tb_l_spacer {
  margin-bottom: 24px !important; }

.tb_l_spacer__smaller {
  margin-bottom: 2px !important; }

.tb_l_spacer__small {
  margin-bottom: 4px !important; }

.tb_l_spacer_b__medium {
  margin-bottom: 8px !important; }

.tb_l_spacer__large {
  margin-bottom: 16px !important; }
  .tb_l_spacer__large:last-child {
    margin-bottom: 0 !important; }

.tb_l_spacer__larger {
  margin-bottom: 24px !important; }

.tb_l_spacer__largest {
  margin-bottom: 32px !important; }
  .tb_l_spacer__largest:last-child {
    margin-bottom: 0 !important; }

.tb_l_force_blocker {
  margin-bottom: 20px; }

.tb_l_pull_left {
  float: left; }

.tb_l_pull_right {
  float: right; }

.tb-l-time-form h1 {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0; }

.tb-l-time-form fieldset {
  border: none; }

.tb_l_form__group {
  border: none;
  padding: 0;
  margin: 0; }
  .tb_l_form__group label {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285714286em; }

.tb_l_form__label {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286em; }

.tb_l_form__control {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286em;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #d1d1d1;
  display: block;
  padding: 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.tb_l_radio {
  position: relative;
  display: block; }

.tb_l_checkbox {
  position: relative;
  display: block; }

.tb_l_checkbox__input {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0; }

.tb_l_checkbox__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286em;
  display: inline-block;
  position: relative;
  min-height: 14px;
  padding: 0 0 0 28px; }

.rtl .tb_l_checkbox__label {
  padding: 0 28px 0 0; }

.tb_l_checkbox__label:before,
.tb_l_checkbox__label:after {
  content: "";
  background-color: #fff;
  border: 2px rgba(0, 0, 0, 0.5) solid;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px; }

.tb_l_checkbox__label:after {
  border: 0;
  background: transparent; }

.rtl .tb_l_checkbox__label:before,
.rtl .tb_l_checkbox__label:after {
  left: auto;
  right: 0; }

/* Error state */
.tb_l_has_error .tb_l_checkbox__label:before,
.tb_l_has-error .tb_l_checkbox__label:after {
  border-color: #e21111; }

/* Indeterminate state */
.tb_l_checkbox__input:indeterminate ~ .tb_l_checkbox__label:before,
.tb_l_checkbox__input--indeterminate ~ .tb_l_checkbox__label:before {
  border: none;
  background: none;
  background-color: #0077cc; }

.tb_l_checkbox__input:indeterminate ~ .tb_l_checkbox__label:after,
.tb_l_checkbox__input--indeterminate ~ .tb_l_checkbox__label:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: #fff; }

.rtl .tb_l_checkbox__input:indeterminate ~ .tb_l_checkbox__label:after,
.rtl .tb_l_checkbox__input--indeterminate ~ .tb_l_checkbox__label:after {
  left: auto;
  right: 5px; }

/* Checked state */
.tb_l_checkbox__input:checked ~ .tb_l_checkbox__label:before {
  border-color: #0077cc; }

.tb_l_checkbox__input:checked ~ .tb_l_checkbox__label:after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
  border: 0;
  left: 0;
  top: 0;
  border-color: #0077cc;
  background-color: #0077cc;
  height: 20px;
  width: 20px; }

/* Disabled state */
.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label:before,
.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label:after {
  border-color: #bdbdbd;
  cursor: not-allowed; }

.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label:after {
  background-color: #bdbdbd; }

.tb_l_checkbox__input:disabled ~ .tb_l_checkbox__label {
  color: #bdbdbd; }

/* Focus state */
.tb_l_checkbox__input:focus ~ .tb_l_checkbox__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3);
  box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3); }

.tb_l_has_error
.tb_l_checkbox__input:not(:checked):focus
~ .tb_l_checkbox__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
  box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3); }

/* Validation */
.tb_l_has_error .tb_l_form__control {
  border-color: #e21111; }

.tb_l_has_error .tb_l_form__control:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
  box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3); }

.tb_l_has_error .tb_l_form__error {
  display: block; }

.tb_l_radio__input {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0; }

.tb_l_radio__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286em;
  display: inline-block;
  position: relative;
  padding: 0 0 0 28px; }

.rtl .tb_l_radio__label {
  padding: 0 28px 0 0; }

.tb_l_radio__label:before,
.tb_l-radio__label:after {
  content: "";
  background-color: #fff;
  border: 2px rgba(0, 0, 0, 0.5) solid;
  border-radius: 50%;
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px; }

.rtl .tb_l_radio__label:before,
.rtl .tb_l_radio__label:after {
  left: auto;
  right: 0; }

.tb_l_radio__label:after {
  border-color: #fff;
  height: 10px;
  width: 10px;
  top: 5px;
  left: 5px;
  opacity: 0; }

.rtl .tb_l_radio__label:after {
  left: auto;
  right: 5px; }

.tb_l_radio__input:checked ~ .tb_l_radio__label:before {
  border-color: #6d6eed; }

.tb_l_radio__input:checked ~ .tb_l_radio__label:after {
  border-color: #6d6eed;
  background-color: #6d6eed;
  opacity: 1; }

.tb_l_has_error .tb_l_radio__label:before {
  border-color: #e21111; }

.tb_l_radio__input:disabled ~ .tb_l_radio__label:before,
.tb_l_radio__input:disabled ~ .tb_l_radio__label:after {
  border-color: #bdbdbd;
  cursor: not-allowed; }

.tb_l_radio__input:disabled ~ .tb_l_radio__label:after {
  background-color: #bdbdbd; }

.tb_l_radio__input:disabled ~ .tb_l_radio__label {
  color: #bdbdbd; }

/* Focus state */
.tb_l_radio__input:focus ~ .tb_l_radio__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3);
  box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.3); }

.tb_l_has_error
.tb_l_radio__input:not(:checked):focus
~ .tb_l_radio__label:before {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3);
  box-shadow: 0 0 0 3px rgba(226, 17, 17, 0.3); }

.card {
  background: #fff;
  border-radius: 2px;
  border-radius: 2px;
  position: relative;
  margin: 0 0 24px; }
