// Global Color Palette
$tb-offwhite: #f7f8fc;
$tb-font-body: #383838;
$tb-constructive: #3bde86;
$tb-destructive: #e34f70;
$tb-brand: #6d6eed;
$tb-brand-light: #a5a6f8;
$tb-compliment: #67cefe;
$tb-text-compliment: #6d6d6d;
$tb-black: #000;
$tb-white: #fff;
$tb-lines: #d0d0d0;

$tibu-primary: #6d6eed;
$tibu-primary-faded: #a5a6f8;

//Typography - font family
$tb-font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto",
  "Helvetica", "Arial", sans-serif;

//Units - global
$tb-l-units-micro: 2px;
$tb-l-units-smallest: 4px;
$tb-l-units-smaller: 8px;
$tb-l-units-small: 12px;
$tb-l-units-body: 14px;
$tb-l-units-medium: 16px;
$tb-l-units-large: 20px;
$tb-l-units-larger: 24px;
$tb-l-units-largest: 32px;

//Units - Percentage
$tl-grid-30: 30%;
$tl-grid-50: 50%;
$tl-grid-20: 20%;
$tl-grid-quat: 33.333%;

//Layout
$tb-l-wrap: 1322px;

//Layout - centered
$tb-l-center: 0 auto;

//Lines & borders
$tb-l-lines: 1px solid $tb-lines;

//floats
$tb-pull-left: left;
$tb-pull-left: right;

// Shadow
$tb-l-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$tb-l-shadow-bottom: 0 0px 4px 0 rgba(0, 0, 0, 0.1);

// Line heights
$tb-l-lineheight: 1.4285714286em;
$tb-l-lineheight_h1: 1.3333333333em;
$tb-l-lineheight_h2: 1.4em;

// Fonts
//fonts
$default-font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto,
  Helvetica, Arial, sans-serif;
$default-font-size: 14px;
$default-font-weight: 400;
